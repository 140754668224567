<template>
    <nav class="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md navbar-light" id="sidebarSmall">
        <div v-if="menu!=null" class="container-fluid">

            <!-- Toggler -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarSmallCollapse" aria-controls="sidebarSmallCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Brand -->
            <a class="navbar-brand" href="/">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="" x="0px" y="0px" viewBox="0 0 46 46" enable-background="new 0 0 46 46" xml:space="preserve" class="injected-svg mx-auto fill-primary" style="height: 42px; fill: #0176ff!important;">
                    <polygon opacity="0.7" points="45,11 36,11 35.5,1"></polygon>
                    <polygon points="35.5,1 25.4,14.1 39,21"></polygon>
                    <polygon opacity="0.4" points="17,9.8 39,21 17,26"></polygon>
                    <polygon opacity="0.7" points="2,12 17,26 17,9.8"></polygon>
                    <polygon opacity="0.7" points="17,26 39,21 28,36"></polygon>
                    <polygon points="28,36 4.5,44 17,26"></polygon>
                    <polygon points="17,26 1,26 10.8,20.1"></polygon>
                </svg>
            </a>
            <!-- Collapse -->
            <div class="collapse navbar-collapse" id="sidebarSmallCollapse">
                <!-- Navigation -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a @click="$router.push('/offer');" class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='offer'}" title="Offer">
                            <i class="fe fe-inbox"></i> <span class="d-md-none">Offer</span>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav">
					<li class="nav-item dropend">
						<a class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='release'}" id="sidebar_logistics" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Release">
							<i class="fe fe-shopping-bag"></i> <span class="d-md-none">Release</span>
						</a>
						<ul class="dropdown-menu" aria-labelledby="sidebar_logistics">
							<li class="dropdown-header d-none d-md-block">
								<h6 class="text-uppercase mb-0">Release</h6>
							</li>
							<li>
								<a @click="$router.push('/release')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='release'}">
									Release
								</a>
							</li>
							<li>
								<a @click="$router.push('/release/index_pn')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='release'&&$router.currentRoute.value.name.l2=='index_pn'}">
									P/N
								</a>
							</li>
						</ul>
					</li>
                </ul>
                <ul v-if="check('/logistics/spares/')" class="navbar-nav">
                    <li class="nav-item dropend">
                        <a class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='logistics'}" id="sidebar_logistics" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Логистика">
                            <i class="fe fe-shopping-cart"></i> <span class="d-md-none">Логистика</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="sidebar_logistics">
                            <li class="dropdown-header d-none d-md-block">
                                <h6 class="text-uppercase mb-0">Логистика</h6>
                            </li>
                            <li>
                                <a @click="$router.push('/logistics/spares')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='logistics'&&$router.currentRoute.value.name.l2=='spares'}">
									Spares
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
				<ul v-if="check('/payment/provider/')" class="navbar-nav">
					<li class="nav-item dropend">
						<a class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='payment'}" id="sidebarSmallDashboards" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Справочники">
							<i class="fe fe-dollar-sign"></i> <span class="d-md-none">Оплата</span>
						</a>
						<ul class="dropdown-menu" aria-labelledby="sidebarSmallDashboards">
							<li class="dropdown-header d-none d-md-block">
								<h6 class="text-uppercase mb-0">Оплата</h6>
							</li>
							<li>
								<a @click="$router.push('/payment/provider')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='payment'&&$router.currentRoute.value.name.l2=='client'}">
									Расчет с поставщиками
								</a>
							</li>
							<li>
								<a @click="$router.push('/payment/invoice')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='payment'&&$router.currentRoute.value.name.l2=='invoice'}">
									Invoice
								</a>
							</li>
							<li>
								<a @click="$router.push('/payment/client')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='payment'&&$router.currentRoute.value.name.l2=='client'}">
									Оплата от клиентов
								</a>
							</li>
							<li>
								<a @click="$router.push('/payment/finance')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='payment'&&$router.currentRoute.value.name.l2=='finance'}">
									Поток
								</a>
							</li>
						</ul>
					</li>
				</ul>
				<ul v-if="check('/directories/provider/')" class="navbar-nav">
					<li class="nav-item dropend">
						<a class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='directories'}" id="sidebarSmallDashboards" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Справочники">
							<i class="fe fe-database"></i> <span class="d-md-none">Справочники</span>
						</a>
						<ul class="dropdown-menu" aria-labelledby="sidebarSmallDashboards">
							<li class="dropdown-header d-none d-md-block">
								<h6 class="text-uppercase mb-0">Справочники</h6>
							</li>
							<li v-if="check('/directories/exchange/')">
								<a @click="$router.push('/directories/exchange')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='exchange'}">
									Курсы валют
								</a>
							</li>
							<li v-if="check('/directories/client/')">
								<a @click="$router.push('/directories/client')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='client'}">
									Клиенты
								</a>
							</li>
							<li v-if="check('/directories/provider/')">
								<a @click="$router.push('/directories/provider')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='provider'}">
									Поставщики
								</a>
							</li>
							<li v-if="check('/directories/intermediary/')">
								<a @click="$router.push('/directories/intermediary')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='intermediary'}">
									Посредники
								</a>
							</li>
							<li v-if="check('/directories/forwarder/')">
								<a @click="$router.push('/directories/forwarder')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='forwarder'}">
									Транспортные компании
								</a>
							</li>
							<li v-if="check('/directories/ship_to/')">
								<a @click="$router.push('/directories/ship_to')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='ship_to'}">
									Склады
								</a>
							</li>
							<li v-if="check('/directories/bank/')">
								<a @click="$router.push('/directories/bank')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='bank'}">
									Банки
								</a>
							</li>
							<li v-if="check('/directories/unit/')">
								<a @click="$router.push('/directories/unit')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='unit'}">
									Компании
								</a>
							</li>
							<li v-if="check('/directories/location/')">
								<a @click="$router.push('/directories/location')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='directories'&&$router.currentRoute.value.name.l2=='location'}">
									Местоположение груза
								</a>
							</li>
						</ul>
					</li>
				</ul>
                <ul v-if="check('/reports/pn/')" class="navbar-nav">
                    <li class="nav-item dropend">
                        <a class="nav-link dropdown-toggle" id="sidebarSmallDashboards1" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Отчеты">
                            <i class="fe fe-trending-up"></i> <span class="d-md-none">Отчеты</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="sidebarSmallDashboards1">
                            <li class="dropdown-header d-none d-md-block">
                                <h6 class="text-uppercase mb-0">Отчеты</h6>
                            </li>
                            <li>
                                <a class="dropdown-item">
									<a @click="$router.push('/reports/pn')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='pn'}">
                                     PN
									</a>
                                </a>
                            </li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/staff')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='staff'}">
										Staff
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/release')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='release'}">
										Release
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/spares')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='spares'}">
										Spares
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/period')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='period'}">
										Периоды
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/pn_sum')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='pn_sum'}">
										PN sum
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/price')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='price'}">
										Price
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/offer_only')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='offer_only'}">
										Offer only
									</a>
								</a>
							</li>
							<li>
								<a class="dropdown-item">
									<a @click="$router.push('/reports/client')" class="dropdown-item" :class="{active:$router.currentRoute.value.name.l1=='reports'&&$router.currentRoute.value.name.l2=='client'}">
										Client
									</a>
								</a>
							</li>
                        </ul>
                    </li>
                </ul>
                <ul v-if="check('/settings/users/')" class="navbar-nav">
                    <li class="nav-item dropend">
                        <a class="nav-link dropdown-toggle" :class="{active:$router.currentRoute.value.name.l1=='settings'}" id="sidebarSmallDashboards2" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="dashboards" title="Настройки">
                            <i class="fe fe-sliders"></i> <span class="d-md-none">Настройки</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="sidebarSmallDashboards2">
                            <li class="dropdown-header d-none d-md-block">
                                <h6 class="text-uppercase mb-0">Настройки</h6>
                            </li>
                            <li>
                                <a @click="$router.push('/settings/users')" class="dropdown-item"  :class="{active:$router.currentRoute.value.name.l1=='settings'&&$router.currentRoute.value.name.l2=='users'}">
                                    Пользователи
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <!-- Push content down -->
                <div class="mt-auto"></div>
                <!-- User (md) -->
                <div class="navbar-user d-none d-md-flex flex-column" id="sidebarSmallUser">
                    <!-- Dropup -->
                    <div class="dropend">
                        <!-- Toggle -->
                        <a href="#" id="sidebarSmallIconCopy" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="avatar avatar-sm avatar-online">
                                <img src="@/img/dd/new.jpg" class="avatar-img rounded-circle" alt="...">
                            </div>
                        </a>

                        <!-- Menu -->
                        <div class="dropdown-menu" aria-labelledby="sidebarSmallIconCopy">
							<li class="dropdown-header d-none d-md-block">
								<h4 class="text mb-0">{{$store.getters.fullname}}</h4>
							</li>
                            <a @click="$router.push('/change-password')" class="dropdown-item">Сменить пароль</a>
                            <hr class="dropdown-divider">
                            <a @click="logout" class="dropdown-item">Выход</a>
                        </div>

                    </div>

                </div>
            </div> <!-- / .navbar-collapse -->
        </div>
    </nav>
</template>
<script>
import { Dropdown } from 'bootstrap';
import axios from 'axios';
import config from '@/config.js'
export default {
    name: 'lg-menu',
	data() {
		return {
			menu: null,
			js_ok: false,
		}
	},
    methods: {
        logout: function ()
		{
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
		async load()
		{
			axios({url: config.API_LOCATION  })
				.then(resp => {
					this.menu=resp.data.menu;
				})
				.catch(err => {
					console.log(err)
				})
		},
		check(link,menu=null)
		{
			if(menu==null)
				menu=this.menu.category;
			for(let id in menu){
				if(menu[id].link==link)
					return true;
				if(menu[id].hasOwnProperty('category'))
					if(this.check(link,menu[id].category))
						return true;
			}
			return false;
		}
    },
    mounted()
	{
		this.load();

    },
	updated()
	{
		if(this.menu==null||this.js_ok)
			return;
		const selectors = '.navbar .dropup, .navbar .dropend, .navbar .dropdown, .navbar .dropstart';
		const dropdowns = document.querySelectorAll(selectors);
		const NAVBAR_BREAKPOINT = 767;

		if (window.innerWidth > NAVBAR_BREAKPOINT) {
			dropdowns.forEach((dropdown) => {
				const toggle = dropdown.querySelector('[data-bs-toggle="dropdown"]');
				const instance = new Dropdown(toggle);

				dropdown.addEventListener('mouseenter', () => {
					instance.show();
				});

				dropdown.addEventListener('mouseleave', () => {
					instance.hide();
				});
			});
		}
		this.js_ok=true;
	}
}
</script>